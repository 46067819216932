import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf'
import PaintPDFFr from '../../pdfs/PAINT-Colour-Cross-Ref-FR.pdf';
import './pdfs.css';

const PaintFr = () => {
  const [numPages, setNumPages] = useState(null);
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    setIsClient(true)
  }, [])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
	<div>
    {isClient && (<Document file={PaintPDFFr} loading="" onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from(
        new Array(numPages),
        (el, index) => (
          <Page loading=""
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ),
      )}
    </Document>)
	}
	</div>
  );
}

export default PaintFr